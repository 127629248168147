import React from 'react';
import GotoInboxInput from "./components/GotoInboxInput";

function Header() {
  return (
    <header
      className="main-header main-header-01 headroom navbar-light fixed-top fixed-dark navbar-transparent header-height">
      <nav className="navbar">
        <div className="container navbar-expand-lg">
          { /* Logo */}
          <a className="navbar-brand header-navbar-brand" href="/">
            <img className="logo-dark" src="/assets/img/logo.svg" title="" alt="" />
            <img className="logo-light" src="/assets/img/logo-light.svg" title="" alt="" />
          </a><br />
          { /* Logo */}
          { /* Mobile Toggle */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span className="toggler-icon"></span>
          </button>
          { /* End Mobile Toggle */}
          { /* Menu */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
              <li className="nav-item dropdown">
                <a href="/#" className="nav-link">Home</a>
              </li>
              <li className="nav-item dropdown">
                <a href="/#pricing" className="nav-link">Pricing</a>
              </li>
              <li className="nav-item dropdown">
                <a href="/#howdoesitwork" className="nav-link">How does it work?</a>
              </li>
              <li className="nav-item dropdown">
                <a href="mailto:support@maildrop.dev?subject=Customer Support" className="nav-link">Support</a>
              </li>
            </ul>
          </div>
          { /* End Menu */}
          <div className="nav flex-column flex-lg-row d-none d-lg-flex">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <a href="mailto:hello@maildrop.dev?subject=Sales Request"
                   className="btn btn-sm btn-secondary mb-0 ms-2 text-nowrap">Contact
                  sales</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="col-12 col-md-6 col-lg-4 offset-md-6 offset-lg-8">
            <GotoInboxInput />
          </div>
        </div>
      </nav>
      { /* End Header */}
    </header>
  );
}

export default Header;
