import React, { useRef, useState } from 'react';

function createRedirectUrl(email) {
  const userAddress = email.replace(/@.*$/, "");
  return `/mailbox/${userAddress}`
}

function GotoInboxInput() {
  const [email, setEmail] = useState("")
  const linkRef = useRef(null);
  const redirectUrl = createRedirectUrl(email);

  const handleInputKeyUp = (event) => {
    if (event.code === "Enter") {
      event.preventDefault();
      linkRef.current?.click()
    }
  }

  return (
    <div className="d-flex flex-row mb-2 p-1 bg-white input-group shadow-lg">
      <input type="email" className="form-control form-control-sm rounded-0 border-0"
             value={email}
             onChange={e => setEmail(e.target.value)}
             onKeyUp={handleInputKeyUp}
             placeholder="Enter public MailDrop inbox" />
      <a className="btn btn-sm btn-primary rounded-0 flex-shrink-0" href={redirectUrl} role="button" ref={linkRef}>Go</a>
    </div>
  );
}

export default GotoInboxInput;
