import React from 'react';
import './HomePage.css';
import Header from './Header';
import Footer from './Footer';

function HomePage() {
    return (
        <>
            <Header/>
            { /* Main */}
            <main>
                { /* Home Banner */}
                <section id="home" className="effect-section bg-gray-100">
                    <div className="header-height-bar"></div>
                    <div className="effect bg-primary effect-skew"></div>
                    <div className="container position-relative">
                        <div className="row min-vh-100 py-10 align-items-center">
                            <div className="col-lg-6 col-xl-5 mb-8 mb-lg-0">
                                <h1 className="display-4 text-white">Receive catch-all emails using your own domain
                                    for <br/> <span className="typed text-white"
                                                    data-elements="Your Developers.,Your Sales Team.,Your Support Team.,Your Startup.,IT Solutions."></span>
                                </h1>
                                <div className="pt-2">
                                    <p className="fs-5 text-white">MailDrop redirects emails
                                        for &lt;anything&gt;@yourdomain.com to your inbox. Unlimited subdomain
                                        redirects
                                        included.</p>
                                </div>
                                <div className="pt-3">
                                    <a className="btn btn-light me-3" href="/#pricing">Pricing</a>
                                    <a className="btn btn-outline-light" href="/#howdoesitwork">How does it work?</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7 pt-lg-8">
                                <iframe width="100%" height="400px" src="https://www.youtube.com/embed/LhJvy3ONl2M"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>

                                <div className="pt-7 d-flex flex-wrap justify-content-center">
                                    <a className="btn btn-secondary btn-sm rounded-pill py-1 px-3 me-2 mb-2"
                                       href="#pricing">Pricing</a>
                                    <a className="btn btn-secondary btn-sm rounded-pill py-1 px-3 me-2 mb-2"
                                       href="#howdoesitwork">How does it work?</a>
                                    <a className="btn btn-secondary btn-sm rounded-pill py-1 px-3 me-2 mb-2"
                                       href="mailto:support@maildrop.dev?subject=Support Request">Support</a>
                                    <a className="btn btn-secondary btn-sm rounded-pill py-1 px-3 me-2 mb-2"
                                       href="#testimonials">What people say about us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                { /* End Home Banner */}
                { /* Section */}
                <section id="howdoesitwork" className="section bg-gray-100 pb-5">
                    <div className="container" style={{maxWidth: '1440px'}}>
                        <div className="row section-heading justify-content-center text-center">
                            <div className="col-lg-6">
                                <h2 className="h1 bg-primary-after after-50px pb-3 mb-3">
                                    <span className="display-3 text-primary d-block">How does it work?</span>
                                </h2>
                            </div>
                        </div>
                        <div className="row gy-7 gx-lg-7 justify-content-center">
                            <div className="col-sm-12 col-lg-8">
                                <img src="/assets/img/maildrop-architecture.png" />
                            </div>
                        </div>
                    </div>
                </section>
                { /* End Section */}
                { /* Section */}
                <section id="pricing" className="section bg-gray-100 pb-5">
                    <div className="container" style={{maxWidth: '1440px'}}>
                        <div className="row section-heading justify-content-center text-center">
                            <div className="col-lg-6">
                                <h2 className="h1 bg-primary-after after-50px pb-3 mb-3">
                                    Pricing
                                </h2>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center gy-4">
                            <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s"
                                 data-wow-delay="0.1s"
                                 style={{visibility: 'visible', animationDuration: '0.4s', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top rounded">
                                    <div className="pb-3 mb-3 border-bottom">
                                        <h5 className="m-0 h1">£20<span style={{fontSize: '14pt'}}>/month</span></h5>
                                    </div>
                                    <h6 className="mb-2">Basic Package</h6>
                                    <ul className="list-unstyled mb-0">
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> 500 emails per month
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> 1 Domain
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Unlimited Subdomains
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-x-circle-fill text-danger me-1"></i> Attachments
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Email support
                                        </li>
                                    </ul>
                                    <div className="pt-4 mt-3 border-top">
                                        <a href="mailto:hello@maildrop.dev?subject=Sales Request" className="btn btn-primary">Sign Up</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s"
                                 data-wow-delay="0.1s"
                                 style={{visibility: 'visible', animationDuration: '0.4s', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top rounded">
                                    <div className="pb-3 mb-3 border-bottom">
                                        <label className="bg-secondary text-white px-4 py-1 mb-4 ms-n5">Popular
                                            Choice</label>
                                        <h5 className="m-0 h1">£40<span style={{fontSize: '14pt'}}>/month</span></h5>
                                    </div>
                                    <h6 className="mb-2">Basic Package</h6>
                                    <ul className="list-unstyled mb-0">
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> 2,000 emails per month
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> 10 Domains
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Unlimited Subdomains
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Attachments up to 10MB
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Email support
                                        </li>
                                    </ul>
                                    <div className="pt-4 mt-3 border-top">
                                        <a href="mailto:hello@maildrop.dev?subject=Sales Request" className="btn btn-primary">Sign Up</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-duration=".4s"
                                 data-wow-delay="0.1s"
                                 style={{visibility: 'visible', animationDuration: '0.4s', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                                <div className="shadow shadow-lg-hover px-4 py-6 bg-white hover-top rounded">
                                    <div className="pb-3 mb-3 border-bottom">
                                        <h5 className="m-0 h1">£80<span style={{fontSize: '14pt'}}>/month</span></h5>
                                    </div>
                                    <h6 className="mb-2">Basic Package</h6>
                                    <ul className="list-unstyled mb-0">
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> 10,000 emails per month
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Unlimited Domains
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Unlimited Subdomains
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Attachments up to 40MB
                                        </li>
                                        <li className="py-2"><i
                                            className="bi-check-circle-fill text-secondary me-1"></i> Email support
                                        </li>
                                    </ul>
                                    <div className="pt-4 mt-3 border-top">
                                        <a href="mailto:hello@maildrop.dev?subject=Sales Request" className="btn btn-primary">Sign Up</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                { /* End Section */}
                { /* Section */}
                {/*<section id="features" className="section bg-gray-100 pb-5">*/}
                {/*    <div className="container" style={{maxWidth: '1440px'}}>*/}
                {/*        <div className="row section-heading justify-content-center text-center">*/}
                {/*            <div className="col-lg-6">*/}
                {/*                <h2 className="h1 bg-primary-after after-50px pb-3 mb-3">*/}
                {/*                    Features*/}
                {/*                </h2>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row gy-7 gx-lg-7">*/}
                {/*            <div className="col-sm-6 col-lg-4">*/}
                {/*                Features*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                { /* End Section */}
                { /* Section */}
                <section id="testimonials" className="section bg-gray-100 pb-5">
                    <div className="container">
                        <div className="row section-heading justify-content-center text-center wow fadeInUp"
                             data-wow-duration="0.3s"
                             style={{visibility: 'visible', animationDuration: '0.3s', animationName: 'fadeInUp'}}>
                            <div className="col-lg-8 col-xl-6">
                                <h3 className="h1 bg-primary-after after-50px pb-3 mb-3">Testimonials</h3>
                            </div>
                        </div>


                        <div
                            className="swiper swiper-container wow fadeInUp swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden"
                            data-wow-duration="0.5s" data-wow-delay="0.1s" data-swiper-options={JSON.stringify({
                                  'slidesPerView': 1,
                                  'spaceBetween': 24,
                                  'pagination': {
                                    'el': '.swiper-pagination',
                                    'clickable': true
                                  },
                                  'breakpoints': {
                                    '650': {
                                      'slidesPerView': 2
                                    },
                                    '991': {
                                      'slidesPerView': 2
                                    },
                                    '1024': {
                                      'slidesPerView': 3
                                    }
                                  }
                                })}
                            style={{visibility: 'visible', animationDuration: '0.5s', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                            <div className="swiper-wrapper" id="swiper-wrapper-64b0569424af26d8" aria-live="polite">
                                <div className="swiper-slide swiper-slide-active"
                                     style={{width: '376px', marginRight: '24px'}} role="group" aria-label="1 / 4">
                                    <div className="border text-center mt-5 mb-4 rounded-3 bg-white">
                                        <div className="icon-lg bg-secondary text-white rounded-circle mt-n5">
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <div className="p-4">
                                            <p style={{height: '110px'}}>MailDrop saved us valuable development time by providing a quick and easy way to test our email flows.</p>
                                            <h5 className="h6 m-0">Lawrence Wagerfield</h5>
                                            <label className="fw-600 small m-0">Founder, Upload.io</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-next"
                                     style={{width: '376px', marginRight: '24px'}} role="group" aria-label="2 / 4">
                                    <div className="border text-center mt-5 mb-4 rounded-3 bg-white">
                                        <div className="icon-lg bg-secondary text-white rounded-circle mt-n5">
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <div className="p-4">
                                            <p style={{height: '110px'}}>We'd been looking around the market for a service doing what MailDrop does for a long time. We were so happy to find it and turn off our old test email servers.</p>
                                            <h5 className="h6 m-0">Oliver Jones</h5>
                                            <label className="fw-600 small m-0">MailDrop customer since 2021</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" style={{width: '376px', marginRight: '24px'}} role="group"
                                     aria-label="3 / 4">
                                    <div className="border text-center mt-5 mb-4 rounded-3 bg-white">
                                        <div className="icon-lg bg-secondary text-white rounded-circle mt-n5">
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <div className="p-4">
                                            <p style={{height: '110px'}}>MailDrop made testing emails super easy for our team!</p>
                                            <h5 className="h6 m-0">Nancy Plowright</h5>
                                            <label className="fw-600 small m-0">CTO, Brave Wombat</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide" style={{width: '376px', marginRight: '24px'}} role="group"
                                     aria-label="4 / 4">
                                    <div className="border text-center mt-5 mb-4 rounded-3 bg-white">
                                        <div className="icon-lg bg-secondary text-white rounded-circle mt-n5">
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <div className="p-4">
                                            <p style={{height: '110px'}}>MailDrop is fast, and sends all our emails for many sub domains to one place. I couldn't find anyone else offering this service and it's great.</p>
                                            <h5 className="h6 m-0">David Moore</h5>
                                            <label className="fw-600 small m-0">Head of Engineering, Paloda</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="swiper-pagination position-relative mt-2 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                                <span className="swiper-pagination-bullet swiper-pagination-bullet-active" tabIndex={0}
                                      role="button" aria-label="Go to slide 1" aria-current="true"></span><span
                                className="swiper-pagination-bullet" tabIndex={0} role="button"
                                aria-label="Go to slide 2"></span></div>
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                    </div>
                </section>
                { /* End Section */}
            </main>
            { /* End Main */
            }
            <Footer />
        </>
    )
        ;
}

export default HomePage;
