import React from 'react';

function Footer() {

    const currentYear = new Date().getFullYear()

    return (
        <footer className="bg-white footer">
            <div className="py-7 py-md-8 py-lg-10">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-6 col-md-2 me-auto">
                            <div className="pb-3">
                                <img src="/assets/img/logo.svg" title="" alt=""/>
                            </div>
                            <p className="m-0">Email simplicity, by people who've been there.</p>
                        </div>
                        <div className="col-lg-3 col-6">
                            <h5 className="footer-title-02">About</h5>
                            <ul className="list-unstyled footer-link-01 m-0">
                                <li>
                                    <a className="text-reset" href="#howdoesitwork">How does it work?</a>
                                </li>
                                <li>
                                    <a className="text-reset" href="#pricing">Pricing</a>
                                </li>
                                {/*<li>*/}
                                {/*    <a className="text-reset" href="#features">Features</a>*/}
                                {/*</li>*/}
                                <li>
                                    <a className="text-reset" href="#testimonials">Testimonials</a>
                                </li>
                                {/*<li>*/}
                                {/*    <a className="text-reset" href="#team">Meet the Team</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6">
                            <h5 className="footer-title-02">Resources</h5>
                            <ul className="list-unstyled footer-link-01 m-0">
                                {/*<li>*/}
                                {/*    <a className="text-reset" href="#">Support Center</a>*/}
                                {/*</li>*/}
                                <li>
                                    <a className="text-reset" href="mailto:support@maildrop.dev?subject=Support Request">Customer Support</a>
                                </li>
                                <li>
                                    <a className="text-reset" href="#team">About Us</a>
                                </li>
                                <li>
                                    <a className="text-reset" href="mailto:support@maildrop.dev?subject=Bug Report">Report a bug</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-6">
                            <h6 className="footer-title-02">
                                Information
                            </h6>
                            <address>
                                <p className="mb-2">3rd Floor, 86-90, Paul Street, London,<br/> EC2A 4NE</p>
                                <p className="mb-2"><a className="text-secondary border-bottom border-secondary"
                                                       href="mailto:support@maildrop.dev?subject=Customer Support">support@maildrop.dev</a></p>
                            </address>
                            <div className="nav pt-3">
                                {/*<a className="icon-sm bg-secondary text-white me-2" href="#">
                                    <i className="bi-facebook"></i>
                                </a>
                                <a className="icon-sm bg-secondary text-white me-2" href="#">
                                    <i className="bi-twitter"></i>
                                </a>
                                <a className="icon-sm bg-secondary text-white me-2" href="#">
                                    <i className="bi-instagram"></i>
                                </a> */}
                                <a className="icon-sm bg-secondary text-white me-2" href="#">
                                    <i className="bi-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="m-0"/>
            <div className="container">
                <div className="row py-3 gy-3 align-items-center">
                    <div className="col-md-6">
                        <ul className="nav justify-content-center justify-content-md-start my-2 small list-unstyled footer-link-01 m-0">
                            <li className="p-0 mx-3 ms-md-0 me-md-3"><a href="#"
                                                                        className="text-reset">Privacy Policy</a>
                            </li>
                            <li className="p-0 mx-3 ms-md-0 me-md-3"><a href="#"
                                                                        className="text-reset">Terms of Use</a>
                            </li>
                            <li className="p-0 mx-3 ms-md-0 me-md-3"><a href="#pricing" className="text-reset">Get a
                                Quote</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <p className="small m-0">© { currentYear } Proteus Technologies Ltd. T/A MailDrop
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
