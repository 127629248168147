import React, {useEffect, useRef, useState} from 'react';
import './HomePage.css';
import './Mailbox.css';
import Header from './Header';
import Footer from './Footer';
import {useParams} from 'react-router-dom';
import {initTooltip} from "./utils/dom-utils";

function Mailbox() {
    const params = useParams()
    const prefix = params.prefix?.replace("@maildrop.dev", "")

    const [messages, setMessages] = useState(null)
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null)
    const showMessage = messages?.[selectedMessageIndex];

    const fetchEmails = async () => {
        setMessages(null)
        const result = await fetch(`https://public.maildrop.dev/mail?address=${prefix}`)
        const data = await result.json()
            .catch(() => null)
        setMessages(data?.messages || [])
    }

    useEffect(() => {
        fetchEmails()
    }, [prefix])

    return (
        <>
            <Header/>
            <main>
                <section id="home" className="effect-section bg-gray-100">
                    <div className="header-height-bar"></div>
                    <div className="effect bg-primary effect-skew"></div>
                    <div className="container position-relative">
                        <div className="row py-10 min-vh-100 justify-content-center">
                            <div className="col-12 mb-12 mb-lg-4">
                                <h1 style={{color: "white"}} className="my-5 d-flex justify-content-between">
                                    Messages for {prefix}@maildrop.dev
                                    <button
                                        className="rounded-circle btn white-bg-button icon-lg btn-outline-primary mb-2"
                                        onClick={fetchEmails}>
                                        <i className="bi bi-arrow-clockwise"></i>
                                    </button>
                                </h1>
                                {messages ?
                                    <MailboxList messages={messages}
                                                 setSelectedMessageIndex={setSelectedMessageIndex}
                                                 selectedMessageIndex={selectedMessageIndex}/>
                                    : <div className="d-flex justify-content-center">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                }
                                {showMessage && <MailView showMessage={showMessage}/>}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    );
}

function SortHeader({sortDown, onSortStateChanged, label, width}) {
    function getIcon() {
        switch (sortDown) {
            case true:
                return <i className="bi bi-chevron-down"></i>
            case false:
                return <i className="bi bi-chevron-up"></i>
            case undefined:
                return <i className="bi bi-chevron-expand"></i>
        }
    }

    return <th style={{width: `${width / 12 * 100}%`}}>
        <span className="d-flex">
            {label}
            <span className="icon mx-1 sort-icon"
                  onClick={() => onSortStateChanged(!sortDown)}>
                {getIcon()}
            </span>
        </span>
    </th>
}

function MailboxList({messages, setSelectedMessageIndex, selectedMessageIndex}) {
    const [sortDown, setSortDown] = useState(true)
    const [sortKey, setSortKey] = useState("arrivedAt")

    const getSortState = (key) => {
        return key === sortKey ? sortDown : undefined
    }

    const handleSortStateChange = (direction, key) => {
        setSortDown(direction)
        setSortKey(key)
    }

    const toLower = (s) => String(s).toLowerCase()
    const sortedMessages = messages.sort((a, b) => toLower(a[sortKey]) < toLower(b[sortKey])
        ? sortDown ? -1 : 1
        : sortDown ? 1 : -1)

    return <div className="card card-body">
        <div className="overflow-auto message-list-container">
            <table className="table table-hover">
                <thead>
                <tr className="sticky-top table-light">
                    <SortHeader sortDown={getSortState("arrivedAt")}
                                width={3}
                                onSortStateChanged={(direction) => handleSortStateChange(direction, "arrivedAt")}
                                label="Received"></SortHeader>
                    <SortHeader sortDown={getSortState("from")}
                                width={4}
                                onSortStateChanged={(direction) => handleSortStateChange(direction, "from")}
                                label="From"></SortHeader>
                    <SortHeader sortDown={getSortState("subject")}
                                width={5}
                                onSortStateChanged={(direction) => handleSortStateChange(direction, "subject")}
                                label="Subject"></SortHeader>
                </tr>
                </thead>
                <tbody>
                {sortedMessages.length ? sortedMessages.map((message, i) => {
                        const classes = `message-row ${i === selectedMessageIndex && "table-active"}`

                        return (<tr className={classes} onClick={() => setSelectedMessageIndex(i)} key={i}>
                            <td>{(new Date(message.arrivedAt)).toLocaleString()}</td>
                            <td>{message.from}</td>
                            <td>{message.subject}</td>
                        </tr>)
                    }
                ) : (
                    <tr>
                        <td colSpan={3} className="text-center">
                            No emails have been received yet
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </div>
}

function MailView({showMessage}) {
    initTooltip()
    const mailViewRef = useRef();

    useEffect(() => {
        mailViewRef.current?.scrollIntoView(false)
    }, [showMessage])

    return <div className="card card-body mt-3" ref={mailViewRef}>
        <h5>Subject: {showMessage.subject}</h5>
        <iframe title="message" srcDoc={showMessage.htmlBody} className="card card-body p-4" style={{width: "100%", minHeight: "80vh"}} />
        <h6 className="mt-4">Attachments: {showMessage.attachments.length}</h6>
        {!!showMessage.attachments.length && (<ul>
            {showMessage.attachments.map((attachment, i) =>
                <li key={i}>
                    <span data-toggle="tooltip"
                          data-placement="top"
                          title="If you'd like to download an attachment buy our premium service <view pricing>">
                    {attachment.fileName}
                        <span>
                        {" "} ({Number(attachment.fileSize).toFixed(3)}KB)

                        <i className="bi bi-download mx-2"></i>
                    </span>
                    </span>
                </li>)}
        </ul>)}
    </div>
}

export default Mailbox;
